<template>
  <div class="w-full px-8">
    <ListMenuBar
      search-placeholder-label="Search transaction"
      main-context-button-label="Sales Report"
      use-main-context-button
    ></ListMenuBar>
    <div class="px-12 py-12 my-8 bg-white rounded">
      <ListTableSimple
        :items="items"
        :columns="columns"
        :on-row-click-target="{
          name: 'route.dashboard.transaction.overview',
          targetColumn: 'slug'
        }"
      ></ListTableSimple>
    </div>
  </div>
</template>

<script>
import ListMenuBar from '@/components/List/ListMenuBar.vue'
import ListTableSimple from '@/components/List/ListTableSimple.vue'

export default {
  components: {
    ListMenuBar: ListMenuBar,
    ListTableSimple: ListTableSimple
  },
  data () {
    return {
      columns: [
        {
          label: 'User Name',
          name: 'username'
        },
        {
          label: 'Total Price',
          name: 'totalPrice'
        },
        {
          label: 'Discount',
          name: 'discount'
        },
        {
          label: 'Subscription',
          name: 'subscription'
        },
        {
          label: 'Payment',
          name: 'payment'
        },
        {
          label: 'Date',
          name: 'date'
        },
        {
          label: 'Status',
          name: 'status'
        }
      ],
      items: [
        {
          slug: '839248023',
          username: 'Anna',
          totalPrice: '50.000 IDR',
          discount: '-',
          subscription: 'Monthly pack',
          payment: 'VA',
          date: '11/24/2021',
          status: 'Active'
        },
        {
          slug: '839248023',
          username: 'Anna',
          totalPrice: '50.000 IDR',
          discount: '-',
          subscription: 'Monthly pack',
          payment: 'VA',
          date: '11/24/2021',
          status: 'Active'
        },
        {
          slug: '839248023',
          username: 'Anna',
          totalPrice: '50.000 IDR',
          discount: '-',
          subscription: 'Monthly pack',
          payment: 'VA',
          date: '11/24/2021',
          status: 'Active'
        },
        {
          slug: '839248023',
          username: 'Anna',
          totalPrice: '50.000 IDR',
          discount: '-',
          subscription: 'Monthly pack',
          payment: 'VA',
          date: '11/24/2021',
          status: 'Active'
        },
        {
          slug: '839248023',
          username: 'Anna',
          totalPrice: '50.000 IDR',
          discount: '-',
          subscription: 'Monthly pack',
          payment: 'VA',
          date: '11/24/2021',
          status: 'Active'
        }
      ]
    }
  }
}
</script>
